@import url("https://fonts.googleapis.com/css?family=Orbitron&display=swap");

.headline-text {
  text-align: center;
  padding-bottom: 10px;
  font-family: "Orbitron", sans-serif;
  font-size: 2.5em;
  margin-top: 50px;
}

.rule {
  border: 0;
  height: 1px;
  width: 100%;
  background: rgb(73, 132, 180);
  background-image: -webkit-linear-gradient(
  left,
    white,
    rgb(73, 132, 180),
    white
  );
}

.portfolio-projects {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 70vw;
  margin: 0 auto;
}

.portfolio-blog {
  width: 70vw;
  margin: 0 auto;
}

@media (hover: none) and (pointer: coarse) {
  .portfolio-projects {
    max-width: 400px;
  }
}

@media only screen and (max-width: 320px) {
  .portfolio-blog {
    width: 90vw;
  }
  .portfolio-projects p {
    font-size: 0.9em;
  }
}