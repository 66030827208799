.jumbotron {
  border: 1px solid rgb(214, 212, 212);
  width: 75vw;
  max-width: 1200px;
  margin-top: 50px;
  padding: 50px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-image: linear-gradient(
    to bottom,
    rgb(214, 212, 212) 0%,
    #e9ecef 100%
  );
  background-image: -o-linear-gradient(
    to bottom,
    rgb(214, 212, 212) 0%,
    #e9ecef 100%
  );
  background-image: -moz-linear-gradient(
    to bottom,
    rgb(214, 212, 212) 0%,
    #e9ecef 100%
  );
  background-image: -webkit-linear-gradient(
    to bottom,
    rgb(214, 212, 212) 0%,
    #e9ecef 100%
  );
  background-image: -ms-linear-gradient(
    to bottom,
    rgb(214, 212, 212) 0%,
    #e9ecef 100%
  );
}

.jumbo-email {
  letter-spacing: 1px;
  font-weight: bold;
}

.jumbo-email:hover {
  text-decoration: none;
}

.social-media {
  display: flex;
}

.social-media-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.social-media-img {
  margin: 0 6px;
}

.social-media-img img {
  height: 45px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.social-media-img img:hover {
  transform: scale(1.1);
  transition: 0.3s;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.social-media-text {
  margin-top: 3px;
  color: rgba(10, 10, 10, 0);
  font-size: 0.9em;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.social-media-img:hover ~ .social-media-text {
  color: rgba(10, 10, 10, 1);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.lead {
  padding-right: 20px;
  margin-right: 2em;
}

.rightJumbo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightJumbo img {
  border-radius: 8px;
  border: 1px solid black;
  padding-top: 10px;
  box-shadow: 2px 2px 5px grey;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.jumboMain {
  display: flex;
  flex-direction: row;
}

h1 {
  text-align: center;
  padding-bottom: 10px;
  font-family: "Orbitron", sans-serif;
}

h5 a {
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.card-row {
  display: contents;
}

.card {
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.flip-card {
  background-color: transparent;
  width: 20vw;
  height: 20vw;
  perspective: 1000px;
  margin: 10px 5px;
  font-size: 1.5vw;
}

.flip-card-inner {
  position: relative;
  width: 20vw;
  height: 20vw;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -ms-transition: transform 0.5s;
  -o-transition: transform 0.5s;
}

.flip-image {
  width: 17vw;
  height: 17vw;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border: 1px solid rgb(73, 132, 180);
  background-image: linear-gradient(
    to bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -o-linear-gradient(
    to bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -moz-linear-gradient(
    to bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-linear-gradient(
    to bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -ms-linear-gradient(
    to bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
}

.flip-card-front {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-back {
  color: white;
  transform: rotateY(180deg);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
}

.flip-card-back h3 {
  font-family: "Orbitron", sans-serif;
}

.flip-card-back a {
  color: blue;
  transition: all 500ms ease;
}

.flip-card-back a:hover,
h5 a:hover {
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  text-decoration: none;
  letter-spacing: 0.04em;
}

.flip-card-back h2 {
  font-size: 3vw;
}

.flip-card-desc {
  display: none;
}

.card-youtube-icon {
  margin-right: 10px;
}

.blog {
  width: 100%;
  margin: 10px auto;
  border: 1px solid rgb(214, 212, 212);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-image: linear-gradient(
    to bottom,
    rgb(214, 212, 212) 0%,
    #e9ecef 100%
  );
  background-image: -o-linear-gradient(
    to bottom,
    rgb(214, 212, 212) 0%,
    #e9ecef 100%
  );
  background-image: -moz-linear-gradient(
    to bottom,
    rgb(214, 212, 212) 0%,
    #e9ecef 100%
  );
  background-image: -webkit-linear-gradient(
    to bottom,
    rgb(214, 212, 212) 0%,
    #e9ecef 100%
  );
  background-image: -ms-linear-gradient(
    to bottom,
    rgb(214, 212, 212) 0%,
    #e9ecef 100%
  );
}

.portfolio-blog {
  max-width: 1200px;
}

.blog-banner {
  text-align: center;
  padding: 20px;
}

.blog-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid rgb(214, 212, 212);
}

.blog-title {
  font-weight: 600;
  font-size: 1.1em;
}

.blog-header a:hover {
  text-decoration: none;
}

.blog-created {
  font-size: 1.1em;
}

.blog-body, .blog-footer {
  padding: 10px 10px 0px 10px;
  min-height: 60px;
}

.blog-image {
  height:120px;
  width: 120px;
  border: 1px solid rgb(214, 212, 212);
  float: left;
  margin: 0 10px 0 0;
}

.blog-image img {
  width: 100%;
  border: 1px solid grey;
}

.blog-pagination {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.blog-pagination-next, .blog-pagination-previous {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.footer {
  margin: 20px auto;
  text-align: center;
}

.hidden {
  display: none;
}

/* smartphones, touchscreens */
@media (hover: none) and (pointer: coarse) {
  .lead {
    margin-right: 0;
    padding: 0;
  }

  .social-media-text {
    color: rgba(10, 10, 10, 1);
  }

  .flip-card:hover .flip-card-inner {
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
  }

  .flip-card-desc {
    display: block;
    text-align: center;
  }

  h5 {
    font-size: 1.1em;
  }
  .click-desc {
    font-size: 8px;
    text-align: center;
  }
  .card-group {
    flex-direction: column;
    align-items: center;
  }
}

/* Large devices */
@media only screen and (max-width: 992px) {
  .social-media {
    justify-content: center;
  }
  .jumboMain {
    flex-direction: column;
    text-align: center;
  }
  .rightJumbo {
    margin-top: 40px;
  }
  .rightJumbo img {
    width: 60vw;
  }
}
/* Medium devices */
@media only screen and (max-width: 768px) {
  .card-group {
    align-items: center;
    flex-direction: column;
  }
  .card {
    margin: 20px 0;
  }

  .flip-card,
  .flip-card-inner {
    width: 45vw;
    height: 45vw;
  }

  .flip-image {
    width: 35vw;
    height: 35vw;
  }

  .flip-card-back h2 {
    font-size: 5vw;
  }

  .flip-card-back p {
    font-size: 2.5vw;
  }

  .display-4 {
    font-size: 3rem;
  }
}
/* Small devices */
@media only screen and (max-width: 576px) {
  .display-4 {
    font-size: 1.8rem;
  }

  .jumbotron {
    padding: 20px 0;
    margin-top: 20px;
  }

  .jumbotron p {
    font-size: 1rem;
  }

  .rightJumbo {
    margin-top: 20px;
  }

  p {
    font-size: 1rem;
  }

  h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 320px) {
  .jumbotron {
    width: 90vw;
  }
  .blog-title, .blog-created {
    font-size: 0.9em;
  }
  .blog-image {
    float: none;
    margin-bottom: 5px;
  }
  .blog-illustration {
    display: flex;
    justify-content: center;
  }
  .blog-text, .card-body p, .leftJumbo {
    font-size: 0.9em;
  }
  .blog-footer{
    padding: 10px;
  }
  .leftJumbo p {
    padding: 5px;
    font-size: 1.1em;
  }
  .card-body {
    padding: 5px;
  }
}